import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const ProjektyIndywidualne = () => {
  return (
    <Layout
      title={"Projekty Indywidualne"}
      image={
        <StaticImage
          src={"../assets/images/tlo.jpg"}
          alt={"budynek"}
          placeholder={"blurred"}
          className={"site-banner"}
          style={{ position: "absolute" }}
        />
      }
    >
      <div className={"d-flex flex-column gap-4"}>
        <div className={"fw-bold"}>Budynki mieszkalne</div>
        <div>
          Wykonujemy indywidualne projekty budynków mieszkalnych
          (jednorodzinnych, dwupokoleniowych, wielorodzinnych), które spełniają
          wszystkie wymagania jakie stawia przed Nami Inwestor. Projektowanie
          rozpoczynamy od przedstawienia koncepcji architektonicznej, która ma
          za zadanie przybliżyć wygląd budynku jaki życzy sobie Inwestor.
          Wszelkie zmiany, rozwiązania techniczne i funkcjonalne konsultujemy na
          każdym etapie z Inwestorem, celem wyeliminowania wszystkich
          niejasności i niedogodności. Należy pamiętać, że to Inwestor będzie
          mieszkał w projektowanym budynku a nie jego projektant/architekt.
          Wieloletnie doświadczenie w projektowaniu budynków mieszkalnych
          pozwoliło nam szybko wychwycić, które elementy są realne do wykonania,
          a które stanowią słabe punkty projektu wpływające na jego koszty
          realizacji oraz funkcjonalność. Indywidualne projekty opracowywane są
          w naszym biurze wielobranżowo, tzn. architektura, konstrukcja, branże
          sanitarne oraz elektryczne - opracowywane są na tych samych podkładach
          i stanowią integralną cześć czytelnego projektu budowlanego. Jeżeli
          szukasz czegoś oryginalnego o niepowtarzalnej architekturze, domu
          innego niż reszta osiedla, to projekt indywidualny na pewno spełni
          Twoje oczekiwania.
        </div>
      </div>
      <div className={"d-flex flex-column gap-4"}>
        <div className={"fw-bold"}>Budynki usługowe</div>
        <div>
          Biuro projektowe wykonuje projekty budynków usługowych o dowolnym
          przeznaczeniu, które konieczne jest do prowadzenia wymaganej przez
          Inwestora działalności. Projekty budowlane budynków usługowych
          posiadają wszystkie, wymagane przez Prawo Budowlane, opinie i
          uzgodnienia rzeczoznawców. Koncepcja architektoniczna projektowanego
          budynku usługowego jest aprobowana przez Inwestorem i stanowi podstawę
          opracowania projektu budowlanego. Projekty wykonywane są wielobranżowo
          wraz z opracowaniem technologii odpowiedniej do zakresu prowadzonej
          działalności w budynku. Budynki usługowe jakie projektowaliśmy to
          m.in. obiekty handlowo-usługowe z częścią mieszkalną, warsztaty
          samochodowe, małe budynki biurowe, wielkopowierzchniowe budynki
          budownictwa rolniczego.
        </div>
      </div>
      <div className={"d-flex flex-column gap-4"}>
        <div className={"fw-bold"}>Przyłącza</div>
        <div>
          W zależności od potrzeb Inwestora biuro projektowe wykonuje projekty
          sieci, przyłączy i instalacji wody, kanalizacji sanitarnej,
          kanalizacji deszczowej, gazu. Wszelkie projekty uzbrojenia posiadają
          niezbędne uzgodnienia i klauzule ostateczności wydane przez
          poszczególnych gestorów sieci.
        </div>
      </div>
      <div className={"d-flex flex-column gap-4"}>
        <div className={"fw-bold"}>Zjazdy z drogi</div>
        <div>
          Wykonujemy projekty budowlane zjazdów drogowych z dróg gminnych,
          powiatowych, wojewódzkich i krajowych na działkę Inwestora. Projekty
          posiadają wszelkie uzgodnienia z zarządcami poszczególnych dróg.
          Dodatkowo w przypadku inwestycji budynków usługowych wykonujemy
          projekty wewnętrznej komunikacji pieszo-jezdnej na terenie inwestycji
          wraz z niezbędnymi miejscami parkingowymi.
        </div>
      </div>
    </Layout>
  )
}

export default ProjektyIndywidualne
